import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment, env_passport } from '@spaceti/environments';
import { SpacetiHttpOptions, Envelope } from '@spaceti/models';

@Injectable({
  providedIn: 'root',
})
export class SpacetiHttpService {
  constructor(private http: HttpClient) {}

  public get<ServerResponseType, ToClientType>(
    url: string,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: SpacetiHttpOptions
  ): Observable<Envelope<ToClientType>> {
    const defOptions = requestOptions ? requestOptions : {};
    return this.http.get(url, defOptions).pipe(
      map((resource: any) =>
        this.buildResponse<ServerResponseType, ToClientType>(resource, mapping)
      ),
      catchError((error) => this.projectError<ToClientType>(error))
    );
  }

  public post<ServerResponseType, ToClientType>(
    url: string,
    body: any,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: SpacetiHttpOptions
  ): Observable<Envelope<ToClientType>> {
    const defOptions = requestOptions ? requestOptions : {};
    return this.http.post(url, body, defOptions).pipe(
      map((resource: any) =>
        this.buildResponse<ServerResponseType, ToClientType>(resource, mapping)
      ),
      catchError((error) => this.projectError<ToClientType>(error))
    );
  }

  public put<ServerResponseType, ToClientType>(
    url: string,
    body: any,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: SpacetiHttpOptions
  ): Observable<Envelope<ToClientType>> {
    const defOptions = requestOptions ? requestOptions : {};
    return this.http.put(url, body, defOptions).pipe(
      map((resource: any) =>
        this.buildResponse<ServerResponseType, ToClientType>(resource, mapping)
      ),
      catchError((error) => this.projectError<ToClientType>(error))
    );
  }

  public delete<ServerResponseType, ToClientType>(
    url: string,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: SpacetiHttpOptions
  ): Observable<Envelope<ToClientType>> {
    const defOptions = requestOptions ? requestOptions : {};
    return this.http.delete(url, defOptions).pipe(
      map((resource: any) =>
        this.buildResponse<ServerResponseType, ToClientType>(resource, mapping)
      ),
      catchError((error) => this.projectError<ToClientType>(error))
    );
  }

  public patch<ServerResponseType, ToClientType>(
    url: string,
    body: any,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: SpacetiHttpOptions
  ): Observable<Envelope<ToClientType>> {
    const defOptions = requestOptions ? requestOptions : {};
    return this.http.patch(url, body, defOptions).pipe(
      map((resource: any) =>
        this.buildResponse<ServerResponseType, ToClientType>(resource, mapping)
      ),
      catchError((error) => this.projectError<ToClientType>(error))
    );
  }

  public getUrl(path: string, replace: object = {}): string {
    for (const key in replace) {
      if (replace.hasOwnProperty(key)) {
        path = path.replace(`:${key}`, replace[key]);
      }
    }

    return `${environment.scheme}${environment.auth_host}/${path}`;
  }

  public getCustomUrl(customUrl: string, path: string): string {
    return `${environment.scheme}${customUrl ? customUrl + '.' : ''}${
      environment.auth_host
    }/${path}`;
  }

  public getPassportUrl(path: string): string {
    return `${environment.scheme}${env_passport.domain}/${path}`;
  }

  private buildResponse<ServerType, ClientType>(
    response: Envelope<ServerType> | ServerType,
    mapping?: (rawResponse: ServerType) => ClientType
  ): Envelope<ClientType> {
    return {
      data: mapping != null ? mapping(response as ServerType) : response,
      success: true,
      resultCode: 200,
    } as Envelope<ClientType>;
  }

  private projectError<ClientType>(error: HttpErrorResponse): Observable<Envelope<ClientType>> {
    let err: any = null;
    if (error.error) {
      err = error.error;
    } else {
      if (error.message) {
        err = error.message;
      }
    }
    if (err) {
      // tslint:disable-next-line:no-console
      console.error(err);
    }

    return of({
      success: false,
      data: null,
      resultCode: error.status,
      error: err,
    } as Envelope<ClientType>);
  }
}
