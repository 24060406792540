import { Injectable } from '@angular/core';

import { Observable, Subscriber } from 'rxjs';
import * as auth0 from 'auth0-js';
import { JwtHelperService } from '@auth0/angular-jwt';

import { LocationService, getPermission, getEmailVerified } from '@spaceti/core/common';
import { environment, env_auth0, env_passport } from '@spaceti/environments';
import { Envelope, IAccessToken, Pack } from '@spaceti/models';
import { SpacetiHttpService } from './spaceti-http.service';

interface TokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  created_at: number;
}

@Injectable({
  providedIn: 'root',
})
export class PassportService {
  private auth0 = new auth0.WebAuth(env_auth0);
  private jwt = new JwtHelperService();

  private readonly LOGIN_ROUTE = '/account/login';

  constructor(private locationService: LocationService, private http: SpacetiHttpService) {}

  public login(organization: string, integration?: string, returnUrl?: string): void {
    let state: string;
    const integ = integration?.split(':') ?? [''];
    switch (integ[0]) {
      case 'cisco':
        state = 'auth:cisco:' + organization;
        break;
      case 'qr':
        state = 'auth:qr:' + organization + ':' + integ[1];
        break;
      case 'registration':
        state = 'auth:/account/new/organization';
        break;
      default:
        state = organization + ':' + (returnUrl || '/');
        break;
    }
    const redirectUri = this.locationService.passportRedirectUri();
    this.locationService.redirectToPassport(redirectUri, state, organization);
  }

  public getToken(code: string): Observable<Envelope<TokenResponse>> {
    const url = this.http.getPassportUrl('oauth/token');
    return this.http.post(
      url,
      {
        grant_type: 'authorization_code',
        client_id: env_passport.clientID,
        code: code,
        redirect_uri: this.locationService.passportRedirectUri(),
      },
      (resp) => resp as TokenResponse
    );
  }

  public decode(token: string): IAccessToken {
    return this.jwt.decodeToken(token);
  }
}
