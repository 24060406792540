import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment, env_passport } from '@spaceti/environments';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  qr = false;
  private organization: string;

  private readonly PASSPORT_SUBDOMAIN = 'passport';

  private readonly AUTH_SUBDOMAIN = 'auth';
  private readonly AUTH_DEV_PORT = '4200';
  private readonly LOGOUT_ROUTE = '/account/logout';
  private readonly LOGIN_ROUTE = '/account/login';

  private readonly DASH_DEV_PORT = '4201';

  private readonly QR_SUBDOMAIN = 'qr';
  private readonly QR_DEV_PORT = '4202';

  constructor(private router: Router) {}

  private redirectHistory(): boolean {
    const lastRedirect = localStorage.getItem('last_redirect') || '';
    const redirectsTimestamp = lastRedirect.split(',', 3).map((value) => Number(value)) || [];
    if (redirectsTimestamp[0] && redirectsTimestamp[0] + 2000 > Date.now()) {
      this.router.navigate(['/error/400']);
      return false;
    } else {
      redirectsTimestamp.unshift(Date.now());
      localStorage.setItem('last_redirect', redirectsTimestamp.join(','));
      return true;
    }
  }

  private redirect(route: string, disableHistory = false) {
    if (disableHistory) {
      window.location.href = route;
    } else if (this.redirectHistory()) {
      window.location.href = route;
    }
  }

  setOrganization(organization: string): void {
    this.organization = organization;
  }

  redirectToLogout(): void {
    this.redirect(this.getScheme() + this.getAuthDomain() + '/' + this.LOGOUT_ROUTE);
  }

  redirectToAuth(): void {
    this.redirect(this.getScheme() + this.getAuthDomain());
  }

  redirectToLogin(space_id?: number): void {
    if (this.qr) {
      this.redirect(
        this.getScheme() +
          this.getAuthDomain() +
          '/' +
          this.getOrganization() +
          '?integration=qr:' +
          space_id
      );
    } else {
      this.redirect(
        this.getScheme() +
          this.getAuthDomain() +
          '/' +
          this.getOrganization() +
          '/?returnTo=' +
          window.location.pathname
      );
    }
  }

  redirectToRegistration(options?: { accessToken: string }): void {
    this.redirect(
      this.getScheme() +
        this.getAuthDomain() +
        '/account/new/organization' +
        (options?.accessToken ? '?accessToken=' + options.accessToken : '')
    );
  }

  redirectAfterLogin(
    organization: string,
    options?: { accessToken?: string; returnTo?: string; integration?: string; passport?: boolean }
  ): void {
    this.redirect(
      this.getScheme() +
        this.getOrganizationDomain(organization) +
        (options?.returnTo && options.returnTo !== '/' ? options.returnTo : '') +
        (options?.accessToken ? '?accessToken=' + options.accessToken : '') +
        (options?.integration ? '&integration=' + options.integration : ''),
      options?.passport
    );
  }

  redirectQRAfterLogin(
    organization: string,
    options?: { accessToken?: string; space_id?: number; passport?: boolean }
  ): void {
    this.redirect(
      this.getScheme() +
        this.getQrDomain() +
        '/' +
        organization +
        (options?.space_id ? '/bookings/resolve' : '') +
        (options?.accessToken ? '?accessToken=' + options.accessToken : '') +
        (options?.space_id ? '&space_id=' + options.space_id : ''),
      options?.passport
    );
  }

  dashboardUrl(organization: string) {
    return this.getOrganizationDomain(organization);
  }

  loginRedirectUri(): string {
    return this.getScheme() + this.getAuthDomain() + this.LOGIN_ROUTE;
  }

  passportRedirectUri(): string {
    //return 'https://auth.spaceti.eu/passport/callback';
    return this.getScheme() + this.getAuthDomain() + '/passport/callback';
  }

  redirectToPassport(redirectUri: string, state: string, tenant: string): void {
    this.redirect(
      this.getScheme() +
        this.PASSPORT_SUBDOMAIN +
        '.' +
        environment.domain +
        '/oauth/authorize?client_id=' +
        env_passport.clientID +
        '&redirect_uri=' +
        redirectUri +
        '&response_type=code&state=' +
        state +
        '&scope=profile&resolve=true&flow=space_id&space_id=' +
        tenant,
      true
    );
  }

  //https://passport.spaceti.eu/oauth/authorize?client_id=UfgPK-cXeQsqFbWhhYsjZyFL2iDVsktgbZbcQRR4xk8&redirect_uri=https://spaceti.eu/passport/callback&response_type=code&scope=profile&flow=space_id&space_id=spacetihq

  getOrganizationDomain(organization: string): string {
    if (!environment.production) {
      const full = window.location.hostname;
      const parts = full.split('.');
      if (parts[parts.length - 1] === 'localhost')
        return organization + '.localhost:' + this.DASH_DEV_PORT;
      else if (window.location.port && parts.length > 2)
        return organization + '.' + parts.slice(1).join('.') + ':' + this.DASH_DEV_PORT;
      else if (parts.length > 2) return organization + '.' + parts.slice(1).join('.');
      else throw new Error('Unknown hostname');
    } else {
      return organization + '.' + environment.domain;
    }
  }

  getAuthDomain(): string {
    if (!environment.production) {
      const full = window.location.hostname;
      const parts = full.split('.');
      if (parts[parts.length - 1] === 'localhost') return 'localhost:' + this.AUTH_DEV_PORT;
      else if (window.location.port && parts.length > 2)
        return this.AUTH_SUBDOMAIN + '.' + parts.slice(1).join('.') + ':' + this.AUTH_DEV_PORT;
      else if (parts.length > 2) return this.AUTH_SUBDOMAIN + '.' + parts.slice(1).join('.');
      else throw new Error('Unknown hostname');
    } else {
      return this.AUTH_SUBDOMAIN + '.' + environment.domain;
    }
  }

  getQrDomain(): string {
    if (!environment.production) {
      const full = window.location.hostname;
      const parts = full.split('.');
      if (parts[parts.length - 1] === 'localhost') return 'localhost:' + this.QR_DEV_PORT;
      else if (window.location.port && parts.length > 2)
        return this.QR_SUBDOMAIN + '.' + parts.slice(1).join('.') + ':' + this.QR_DEV_PORT;
      else if (parts.length > 2) return this.QR_SUBDOMAIN + '.' + parts.slice(1).join('.');
      else throw new Error('Unknown hostname');
    } else {
      return this.QR_SUBDOMAIN + '.' + environment.domain;
    }
  }

  getOrganization(): string {
    if (!this.organization) {
      const full = window.location.host;
      const parts = full.split('.');

      this.organization = parts[0];
    }

    return this.organization ?? '';
  }

  getScheme(): string {
    return window.location.protocol + '//';
  }
}
