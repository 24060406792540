import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CoreAuthModule } from '@spaceti/core/auth';
import { CoreCommonModule } from '@spaceti/core/common';

import { AppComponent } from './app.component';
import { ROUTES } from './auth.routes';
import { environment } from '@spaceti/environments';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(ROUTES, {
      enableTracing: environment.production ? false : true,
    }),
    CoreAuthModule,
    CoreCommonModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
