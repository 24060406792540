export const setupCiscoIntegration = (
  client_id: string,
  redirect_uri: string,
  response_type: string,
  c_state: string
) => {
  localStorage.setItem('client_id', client_id);
  localStorage.setItem('redirect_uri', redirect_uri);
  localStorage.setItem('response_type', response_type);
  localStorage.setItem('c_state', c_state);

  return true;
};

export const authCiscoIntegration = (tenant: string) => {
  const options = {
    redirectUri: localStorage.getItem('redirect_uri'),
    responseType: localStorage.getItem('response_type'),
    clientID: localStorage.getItem('client_id'),
    audience: 'https://cisco-api',
    scope: tenant,
    state: localStorage.getItem('c_state'),
  };

  localStorage.removeItem('redirect_uri');
  localStorage.removeItem('response_type');
  localStorage.removeItem('client_id');
  localStorage.removeItem('c_state');
  return options;
};
