import { createAction, props } from '@ngrx/store';
import { type } from '@spaceti/utils';
import { Organization } from '@spaceti/models';

export const LoginActionTypes = {
  LoadOrganization: type('[Login screen] Load organization'),
  LoadOrganizationSuccessful: type('[Login screen] Load organization successful'),
  LoadOrganizationFailed: type('[Login screen] Load organization failed'),

  LoadSupport: type('[Login screen] Load Support'),
  LoadSupportSuccessful: type('[Login screen] Load Support successful'),

  LoadSettings: type('[Login screen] Load settings'),
  LoadSettingsSuccessful: type('[Login screen] Load settings successful'),

  LoginContinue: type('[Login screen] Continue button'),

  UserValidation: type('[Login screen] User validation'),
  UserValidationSuccessful: type('[Login screen] User validation successful'),
  UserValidationFailed: type('[Login screen] User validation failed'),
  UserValidationReset: type('[Login screen] User validation reset'),

  PassValidation: type('[Login screen] Password validation '),
  PassValidationSuccessful: type('[Login screen] Password validation successful'),
  PassValidationFailed: type('[Login screen] Password validation failed'),
};

export const LoadOrganizationAction = createAction(
  LoginActionTypes.LoadOrganization,
  props<{ organization: string; returnTo?: string; integration?: string }>()
);

export const LoadOrganizationSuccessfulAction = createAction(
  LoginActionTypes.LoadOrganizationSuccessful,
  props<{ organization: Organization }>()
);

export const LoadSupportAction = createAction(
  LoginActionTypes.LoadSupport,
  props<{
    organization: string;
  }>()
);

export const LoadSupportSuccessfulAction = createAction(
  LoginActionTypes.LoadSupportSuccessful,
  props<{
    numbers: Array<{ icon_url: string; title: string; phone: string }>;
  }>()
);

export const LoadSettingsAction = createAction(
  LoginActionTypes.LoadSettings,
  props<{
    organization: string;
  }>()
);

export const LoadSettingsSuccessfulAction = createAction(
  LoginActionTypes.LoadSettingsSuccessful,
  props<{
    settings: Array<{ id: number; key: string; value: string }>;
  }>()
);

export const LoadOrganizationFailedAction = createAction(
  LoginActionTypes.LoadOrganizationFailed,
  props<{ error: any }>()
);

export const LoginContinueAction = createAction(
  LoginActionTypes.LoginContinue,
  props<{
    email: string;
    password: string;
    integration?: string;
    returnTo?: string;
  }>()
);

export const UserValidationAction = createAction(
  LoginActionTypes.UserValidation,
  props<{
    email: string;
  }>()
);

export const UserValidationSuccessfulAction = createAction(
  LoginActionTypes.UserValidationSuccessful,
  props<{ email: string }>()
);

export const UserValidationFailedAction = createAction(
  LoginActionTypes.UserValidationFailed,
  props<{ email: string; error: any }>()
);

export const UserValidationResetAction = createAction(LoginActionTypes.UserValidationReset);

export const PassValidationAction = createAction(
  LoginActionTypes.PassValidation,
  props<{
    email: string;
    password: string;
    organization: string;
    integration?: string;
    returnTo?: string;
  }>()
);

export const PassValidationSuccessfulAction = createAction(
  LoginActionTypes.PassValidationSuccessful
);

export const PassValidationFailedAction = createAction(
  LoginActionTypes.PassValidationFailed,
  props<{ error: any }>()
);
