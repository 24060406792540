import { IAccessToken, Pack } from '@spaceti/models';

export function getPermission(
  token: IAccessToken,
  organization: string
): string {
  return '';
  return token['https://spaceti-api/permissions'].find(
    (permission) => permission.name === organization
  )?.role;
}

export function getEmailVerified(token: IAccessToken): boolean {
  return token['https://spaceti-api/email_verified'] || false;
}
