import { Injectable } from '@angular/core';
import { SpacetiHttpService } from './spaceti-http.service';
import {
  Envelope,
  Organization,
  OrgRegistrationInfo,
  Pack,
  UserRegistrationInfo,
  UserRolesListRequestObjectModel,
} from '@spaceti/models';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { switchMap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  constructor(
    private http: SpacetiHttpService,
    private authService: AuthService
  ) {}

  public resetPasswordRequest(email: string) {
    const url = this.http.getCustomUrl('auth', 'reset');
    return this.http.post(
      url,
      {
        email: email,
      },
      (resp) => resp
    );
  }

  public registerUserAndOrganization(
    user: UserRegistrationInfo,
    org: OrgRegistrationInfo
  ): Observable<Envelope<any>> {
    const url = this.http.getCustomUrl('auth', 'trial');
    const body = {
      user: {
        email: user.email,
        password: user.password,
        given_name: user.firstName,
        family_name: user.lastName,
      },
      account: {
        name: org.name,
        subdomain: org.domain,
      },
    };
    return this.http.post(url, body, (res) => res);
  }

  public registerOrganization(
    org: OrgRegistrationInfo
  ): Observable<Envelope<any>> {
    // TODO. At this point this *will not work*
    const url = this.http.getCustomUrl('auth', 'accounts');
    const body = {
      account: {
        subdomain: org.domain,
        name: org.name,
      },
    };
    return this.authService.checkSession().pipe(
      switchMap((data) =>
        this.http.post(url, body, (res) => res, {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + data?.data
          ),
          observe: 'body',
        })
      )
    );
  }

  /**
   * Fetches all the roles available in the system
   * @returns Array of objects with all the available roles
   */
  getRoles(
    organization: Pack<Organization>
  ): Observable<Envelope<UserRolesListRequestObjectModel>> {
    const url = this.http.getCustomUrl(
      organization.data.subdomain,
      '/api/roles'
    );
    return this.http.get<
      UserRolesListRequestObjectModel,
      UserRolesListRequestObjectModel
    >(url, (resp) => {
      return resp as UserRolesListRequestObjectModel;
    });
  }
}
