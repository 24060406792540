import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Store } from '@ngrx/store';
import {
  getOrganization,
  LoadOrganizationAction,
  LoginState,
} from '@spaceti/core/auth';
import { Observable, pipe } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

@Injectable()
export class OrganizationExistsGuard implements CanActivate {
  constructor(private store: Store<LoginState>, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const organization = route.paramMap.get('organization');
    const returnTo =
      this.router.getCurrentNavigation()?.extractedUrl?.queryParams?.returnTo;
    const integration =
      route.parent?.data.integrations ??
      this.router.getCurrentNavigation()?.extractedUrl?.queryParams
        ?.integration ??
      '';

    return this.store.select(getOrganization).pipe(
      filter((org) => !org.loading),
      tap((org) => {
        if (
          (!org.data && !org.error) ||
          (org.data && organization !== org.data.subdomain)
        ) {
          this.store.dispatch(
            LoadOrganizationAction({
              organization: organization,
              returnTo: returnTo,
              integration: integration,
            })
          );
        }
      }),
      filter((org) => org.data || org.error),
      take(1),
      map((org) => (org.data ? true : this.router.createUrlTree(['/'])))
    );
  }
}
