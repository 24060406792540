import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LocationService } from '@spaceti/core/common';
import { forkJoin, of } from 'rxjs';
import { delay, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as actions from '../actions';
import { getOrgRegistrationInfo, getUserRegistrationInfo } from '../selectors';
import { AuthService, RegistrationService } from '../services';

@Injectable()
export class RegistrationEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private registrationService: RegistrationService,
    private authService: AuthService,
    private locationService: LocationService
  ) {}

  $registerOrganization = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SubmitOrgFormAction),
      withLatestFrom(
        this.store.select(getUserRegistrationInfo),
        this.store.select(getOrgRegistrationInfo)
      ),
      switchMap(([action, userInfo, orgInfo]) => {
        if (userInfo.email === '' && userInfo.firstName === '') {
          // user already registered, register just the org
          return forkJoin([
            this.registrationService.registerOrganization(orgInfo),
            this.authService.checkSession(),
          ]).pipe(
            delay(10000),
            map(([res, session]) => {
              if (res.success) {
                this.locationService.redirectAfterLogin(orgInfo.domain, {
                  returnTo: '/',
                  accessToken: session.data,
                  integration: 'cisco',
                });
              } else {
                return actions.RegistrationFailureAction({
                  success: res.success,
                  error: res.error?.description || 'Unknown error',
                  resultCode: res.resultCode,
                });
              }
            })
          );
        } else {
          localStorage.setItem('integration', 'cisco');
          return this.registrationService
            .registerUserAndOrganization(userInfo, orgInfo)
            .pipe(
              delay(10000),
              map((res) => {
                if (res.success) {
                  return actions.PassValidationAction({
                    email: userInfo.email,
                    password: userInfo.password,
                    organization: orgInfo.domain,
                  });
                } else {
                  return actions.RegistrationFailureAction({
                    success: res.success,
                    error: res.error?.description || 'Unknown error',
                    resultCode: res.resultCode,
                  });
                }
              })
            );
        }
      })
    )
  );
}
