import { Injectable } from '@angular/core';
import { SpacetiHttpService } from './spaceti-http.service';
import { Envelope } from '@spaceti/models';
import { Observable, of } from 'rxjs';
import { Organization } from '@spaceti/models';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: SpacetiHttpService) {}

  public loadOrganization(organization: string): Observable<Envelope<Organization>> {
    const url = this.http.getCustomUrl('auth', 'accounts/' + organization);
    return this.http.get<any, Organization>(url, (resp) => resp);
  }

  public loadSupport(
    organization: string
  ): Observable<Envelope<Array<{ icon_url: string; title: string; phone: string }>>> {
    const url = this.http.getCustomUrl(organization, 'support_contacts/');
    return this.http.get<any, Array<{ icon_url: string; title: string; phone: string }>>(
      url,
      (resp) => resp
    );
  }

  public loadSettings(organization: string): Observable<Envelope<any>> {
    const url = this.http.getCustomUrl(organization, 'api/v2/settings');
    return this.http.get<any, Array<{ id: number; key: string; value: string }>>(
      url,
      (resp) => resp?.results
    );
  }

  public validateUser(organization: string, email: string) {
    const url = this.http.getCustomUrl('auth', 'users/validate');
    return this.http.post<any, { exists: boolean }>(
      url,
      {
        email: email,
        subdomain: organization,
      },
      (resp) => resp
    );
  }
}
