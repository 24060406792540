import { createReducer, on } from '@ngrx/store';
import { LoginState } from '../models';

import * as actions from '../actions';

const initialState: LoginState = {
  organization: {
    loading: false,
    data: null,
  },
  userEmail: {
    loading: false,
    data: null,
  },
  userPassword: {
    loading: false,
    data: null,
  },
  support: {
    loading: false,
    data: [],
  },
  settings: {
    loading: false,
    data: null,
  },
};

const reducerFce = createReducer(
  initialState,

  on(actions.LoadOrganizationAction, (state, action) => ({
    ...state,
    organization: {
      loading: true,
      data: null,
    },
  })),

  on(actions.LoadOrganizationSuccessfulAction, (state, action) => ({
    ...state,
    organization: {
      loading: false,
      data: action.organization,
    },
  })),

  on(actions.LoadSupportAction, (state, action) => ({
    ...state,
    support: {
      loading: true,
    },
  })),

  on(actions.LoadSupportSuccessfulAction, (state, action) => ({
    ...state,
    support: {
      loading: false,
      data: action.numbers,
    },
  })),


  on(actions.LoadSettingsAction, (state, action) => ({
    ...state,
    settings: {
      loading: true,
    },
  })),

  on(actions.LoadSettingsSuccessfulAction, (state, action) => ({
    ...state,
    settings: {
      loading: false,
      data: action.settings,
    },
  })),

  on(actions.LoadOrganizationFailedAction, (state, action) => ({
    ...state,
    organization: {
      loading: false,
      data: null,
      error: action.error,
    },
  })),

  on(actions.UserValidationAction, (state, action) => ({
    ...state,
    userEmail: {
      loading: true,
      data: null,
    },
  })),

  on(actions.UserValidationSuccessfulAction, (state, action) => ({
    ...state,
    userEmail: {
      loading: false,
      data: action.email,
    },
  })),

  on(actions.UserValidationFailedAction, (state, action) => ({
    ...state,
    userEmail: {
      loading: false,
      data: action.email,
      error: action.error,
    },
  })),

  on(actions.UserValidationResetAction, (state) => ({
    ...state,
    userEmail: {
      loading: false,
      data: null,
      error: null,
    },
    userPassword: {
      loading: false,
      data: null,
      error: null,
    },
  })),

  on(actions.PassValidationAction, (state, action) => ({
    ...state,
    userPassword: {
      loading: true,
      data: null,
    },
  })),

  on(actions.PassValidationSuccessfulAction, (state, action) => ({
    ...state,
    userPassword: {
      loading: false,
      data: '',
    },
  })),

  on(actions.PassValidationFailedAction, (state, action) => ({
    ...state,
    userPassword: {
      loading: false,
      data: null,
      error: action.error,
    },
  }))
);

export function loginReducer(state, action) {
  return reducerFce(state, action);
}
