// TODO, tato třída je prasárna, upravit..
export class LatLng {
  /**
   * The <a href='https://en.wikipedia.org/wiki/Earth_radius#Equatorial_radius'>equatorial radius</a> value in meters
   */
  static RADIUS_EARTH_METERS = 6378137;

  /**
   * The <a href='https://github.com/goldelico/mySTEP/blob/master/MapKit/MKGeometry.m'>equatorial radius</a>
   */
  static POLE_RADIUS = 6356752.314;

  /**
   * The <a href='https://github.com/goldelico/mySTEP/blob/master/MapKit/MKGeometry.m'>equatorial radius</a>
   */
  static EQUATOR_RADIUS = 6378127.0;

  /**
   * The <a href='https://github.com/goldelico/mySTEP/blob/master/MapKit/MKGeometry.m'>equatorial radius</a>
   */
  static POINTS_PER_METER = 72.0 / 0.0254;

  /**
   * The <a href='https://github.com/goldelico/mySTEP/blob/master/MapKit/MKGeometry.m'>equatorial radius</a>
   */
  static MAP_WIDTH =
    2 * Math.PI * LatLng.EQUATOR_RADIUS * LatLng.POINTS_PER_METER;

  /**
   * The <a href='https://github.com/goldelico/mySTEP/blob/master/MapKit/MKGeometry.m'>equatorial radius</a>
   */
  static MAP_HEIGHT = Math.PI * LatLng.POLE_RADIUS * LatLng.POINTS_PER_METER;

  /**
   * The minimum latitude on Earth. This is the minimum latitude representable
   * by Mapbox GL's Mercator projection, because the projection distorts latitude
   * near the poles towards infinity.
   */
  static MIN_LATITUDE = -85.05112878;

  /**
   * The maximum latitude on Earth. This is the maximum latitude representable
   * by Mapbox GL's Mercator projection, because the projection distorts latitude
   * near the poles towards infinity.
   */
  static MAX_LATITUDE = 85.05112878;

  /**
   * The minimum longitude on Earth
   */
  static MIN_LONGITUDE = -180;

  /**
   * The maximum longitude on Earth
   */
  static MAX_LONGITUDE = 180;

  lat: number;
  lng: number;
  alt: number;

  constructor(lat: number, lng: number, alt = 0) {
    if (lat < LatLng.MIN_LATITUDE || lat > LatLng.MAX_LATITUDE) {
      // throw new Error(`Latitude must be in the range`);
    } else if (lng < LatLng.MIN_LONGITUDE || lng > LatLng.MAX_LONGITUDE) {
      // throw new Error(`Longitude must be in the range`);
    }
    this.lat = lat;
    this.lng = lng;
    this.alt = alt;
  }

  getLat() {
    return this.lat;
  }

  setLat(lat) {
    this.lat = lat;
  }

  getLng() {
    return this.lng;
  }

  setLng(lng) {
    this.lng = lng;
  }

  getAlt() {
    return this.alt;
  }

  setAlt(alt) {
    this.alt = alt;
  }

  // Calculate distance between two points
  distanceTo(other: LatLng) {
    if (this.lat === other.getLat() && this.lng === other.getLng()) {
      // return 0.0 to avoid a NaN
      return 0.0;
    }

    const a1 = (Math.PI * this.lat) / 180;
    const a2 = (Math.PI * this.lng) / 180;
    const b1 = Math.PI * other.getLat();
    const b2 = Math.PI * other.getLng();

    const cosa1 = Math.cos(a1);
    const cosb1 = Math.cos(b1);

    const t1 = cosa1 * Math.cos(a2) * cosb1 * Math.cos(b2);
    const t2 = cosa1 * Math.sin(a2) * cosb1 * Math.sin(b2);
    const t3 = Math.sin(a1) * Math.sin(b1);
    const tt = Math.acos(t1 + t2 + t3);

    return LatLng.RADIUS_EARTH_METERS * tt;
  }
}
