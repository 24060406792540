import { createReducer, on } from '@ngrx/store';
import { RegistrationState } from '../models';

import * as actions from '../actions';
import { registrationState } from '../selectors';
import { Statement } from '@angular/compiler';

const initialState: RegistrationState = {
  userInfo: {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    promotionConsent: false,
  },
  orgInfo: {
    name: '',
    domain: '',
  },
  loading: false,
  registrationStep: 1,
  registrationStatus: {
    success: true,
    resultCode: 201,
  },
};

const reducerFce = createReducer(
  initialState,

  on(actions.UserValidationFailedAction, (state, action) => ({
    ...state,
    userInfo: {
      ...state.userInfo,
      email: action.email,
    },
    registrationStep: 2,
  })),

  /*on(actions.PassValidationSuccessfulAction, (state, action) => ({
    ...state,
    registrationStep: 3
  })),*/

  on(actions.SubmitUserFormAction, (state, action) => ({
    ...state,
    userInfo: {
      ...state.userInfo,
      firstName: action.firstName,
      lastName: action.lastName,
      password: action.password,
      promotionConsent: action.promotionConsent,
    },
    registrationStep: 3,
  })),

  on(actions.SubmitOrgFormAction, (state, action) => ({
    ...state,
    orgInfo: {
      name: action.name,
      domain: action.domain,
    },
    registrationStatus: {
      success: true,
      resultCode: 201,
    },
    loading: true,
  })),

  on(actions.RegistrationFailureAction, (state, action) => ({
    ...state,
    registrationStatus: {
      success: action.success,
      error: action.error,
      resultCode: action.resultCode,
    },
    loading: false,
  })),

  on(actions.SetRegistrationStepAction, (state, action) => ({
    ...state,
    registrationStep: action.step,
  }))
);

export function registrationReducer(state, action) {
  return reducerFce(state, action);
}
