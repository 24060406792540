import { Injectable } from '@angular/core';

import { Observable, Subscriber } from 'rxjs';
import * as auth0 from 'auth0-js';
import { JwtHelperService } from '@auth0/angular-jwt';

import {
  LocationService,
  getPermission,
  getEmailVerified,
} from '@spaceti/core/common';
import { environment, env_auth0 } from '@spaceti/environments';
import { IAccessToken, Pack } from '@spaceti/models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth0 = new auth0.WebAuth(env_auth0);
  private jwt = new JwtHelperService();

  private readonly LOGIN_ROUTE = '/account/login';

  constructor(private locationService: LocationService) {}

  public login(
    email: string,
    password: string,
    organization: string,
    integration?: string,
    returnUrl?: string
  ): Observable<Pack<any>> {
    let state;
    const integ = integration.split(':');
    switch (integ[0]) {
      case 'cisco':
        state = 'auth:cisco:' + organization;
        break;
      case 'qr':
        state = 'auth:qr:' + organization + ':' + integ[1];
        break;
      case 'registration':
        state = 'auth:/account/new/organization';
        break;
      default:
        state = organization + ':' + (returnUrl || '/');
        break;
    }
    return new Observable((observer) => {
      this.auth0.login(
        {
          email: email,
          password: password,
          redirectUri: this.locationService.loginRedirectUri(),
          state: state,
          audience: 'https://spaceti-api',
        },
        (err, data) => {
          if (data) {
            observer.next({ success: true, data: data, error: null });
          } else {
            observer.next({ success: false, data: null, error: err });
          }
          observer.complete();
        }
      );
    });
  }

  public authorize(options: {
    redirectUri: string;
    responseType: string;
    clientID: string;
  }) {
    this.auth0.authorize(options);
  }

  public parseHash(hash: string) {
    return new Observable((observer) => {
      this.auth0.parseHash({ hash: hash }, (err, data) => {
        if (data) {
          observer.next(data);
        }
        observer.complete();
      });
    });
  }

  public checkSession(): Observable<Pack<string>> {
    return new Observable((observer) => {
      this.auth0.checkSession(
        {
          redirectUri: this.locationService.loginRedirectUri(),
          scope: 'openid profile email',
          audience: 'https://spaceti-api',
        },
        (err, data) => {
          if (data) {
            observer.next({ success: true, data: data.accessToken });
          } else {
            observer.next({ success: false, data: null, error: err });
          }
          observer.complete();
        }
      );
    });
  }

  public decode(token: string): IAccessToken {
    return this.jwt.decodeToken(token);
  }

  public verified(token: IAccessToken): boolean {
    return getEmailVerified(token);
  }

  public getRole(token: IAccessToken, organization: string): string {
    return getPermission(token, organization);
  }

  public logout() {
    this.auth0.logout({
      returnTo:
        this.locationService.getScheme() + this.locationService.getAuthDomain(),
    });
  }
}
