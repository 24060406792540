import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Organization, Pack } from '@spaceti/models';
import { LoginState } from '../models';

export const loginState = createFeatureSelector<LoginState>('login');

export const getOrganization = createSelector<
  LoginState,
  [LoginState],
  Pack<Organization>
>(loginState, (x) => x.organization);

export const getUserEmail = createSelector<
  LoginState,
  [LoginState],
  Pack<string>
>(loginState, (x) => x.userEmail);

export const getUserPassword = createSelector<
  LoginState,
  [LoginState],
  Pack<string>
>(loginState, (x) => x.userPassword);

export const getSupport = createSelector(loginState, (x) => x.support);

export const getSettings = createSelector(loginState, (x) => x.settings);
