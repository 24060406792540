import { ActionReducerMap } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { loginReducer } from './login.reducers';
import { registrationReducer } from './registration.reducers';
import { LoginState, RegistrationState } from '../models';

export const reducers: ActionReducerMap<{
  router: RouterReducerState;
  login: LoginState;
  registration: RegistrationState
}> = {
  router: routerReducer,
  login: loginReducer,
  registration: registrationReducer
};
