import { createAction, props } from '@ngrx/store';
import { UserRegistrationInfo, OrgRegistrationInfo } from '@spaceti/models';
import { type } from '@spaceti/utils';

export const RegistrationActionTypes = {
  SubmitUserForm: type('[User registration screen] submit user form'),
  SubmitOrgForm: type('[Org registration screen] submit org form'),
  RegistrationSuccess: type('[Org registration screen] registration success'),
  RegistrationFailureAction: type('[Org registration screen] registration failure'),
  SetRegistrationStepAction: type('[Organization registration flow] set registration step')
}

export const SubmitUserFormAction = createAction(
  RegistrationActionTypes.SubmitUserForm,
  props<UserRegistrationInfo>()
);

export const SubmitOrgFormAction = createAction(
  RegistrationActionTypes.SubmitOrgForm,
  props<OrgRegistrationInfo>()
);

export const RegistrationSuccessAction = createAction(
  RegistrationActionTypes.RegistrationSuccess
)

export const RegistrationFailureAction = createAction(
  RegistrationActionTypes.RegistrationFailureAction,
  props<{success: boolean, error: any, resultCode?: number}>()
)

export const SetRegistrationStepAction = createAction(
  RegistrationActionTypes.SetRegistrationStepAction,
  props<{step: number}>()
)
