// ------------------------------------------------------------------------------
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// ------------------------------------------------------------------------------

// import { AuthOptions } from 'auth0-js';

// ------------------------------------------------------------------------------
// Development variables
// ------------------------------------------------------------------------------

import { NavigationSet } from '@spaceti/models';

export const environment = {
  production: false,
  hmr: false,
  supported_languages: ['en', 'cs', 'de', 'nl', 'es', 'fr', 'da', 'nb', 'pt', 'it', 'sv', 'uk', 'ja'],
  default_language: 'en',
  release: 'GITHUB_RELEASE_HASH',
  mapbox_token:
    'pk.eyJ1IjoibW9sa2kiLCJhIjoiY2l5eDZnZWNlMDAwcDMycXBnaTR0bndsZSJ9.ahqOOuFo_A08FkvcNrXIIA',
  google_maps_api_key: 'AIzaSyAePB__YYMXKsVmHKEBDQBZMCSxRNH1mX4',
  sentry_token: 'https://382230314f23424c9c9bfaac60410bbf@sentry.io/1434552',
  auth_host: 'spaceti-dev.com',
  host: 'spaceti-dev.com',
  microservices_host: 'services.spaceti-dev.com',
  public_api: 'proxy-public-api-nginx-staging.herokuapp.com/v2',
  scheme: 'https://',
  domain: 'spaceti.eu',
  whitelisted_domains: [
    '.spaceti-dev.com',
    '.spaceti.co',
    '.spaceti.net',
    '.spaceti-dev.co',
    'proxy-public-api-nginx-staging.herokuapp.com',
    'localhost',
  ],
};

export const env_passport = {
  domain: 'passport.spaceti.eu',
  clientID: 'UfgPK-cXeQsqFbWhhYsjZyFL2iDVsktgbZbcQRR4xk8',
};

export const env_auth0 = {
  domain: 'login.spaceti.eu',
  redirectUri: 'http://localhost:4200/',
  clientID: 'qM5utfBx1LWm9WZGIDqRGt8eanJ04Zsh',
  responseType: 'token id_token',
  plugins: [],
};

export const userRoles = {
  ocupant_role: 1,
  data_analyst: 8,
  space_manager_role: 7,
  admin_role: 99,
};

export const firebaseConfig = {
  apiKey: 'AIzaSyAa2qFOnuiGpvNDQp6FamMdbU8ccPxU7bE',
  authDomain: 'spaceti-c0c6a.firebaseapp.com',
  databaseURL: 'https://spaceti-c0c6a.firebaseio.com',
  projectId: 'spaceti-c0c6a',
  storageBucket: 'spaceti-c0c6a.appspot.com',
  messagingSenderId: '475205687150',
  appId: '1:475205687150:web:c8c42ed625469d7fca537f',
  measurementId: 'G-HK77P593EV',
};

export const navigationLinks: NavigationSet[] = [
  {
    route: '/buildings-management',
    header: 'left-menu-category-administration',
    returnTo: '/',
    selectBuilding: true,
    set: [
      {
        category: 'Base',
        links: [
          {
            name: 'Dashboard',
            translation: 'analytics-dashboard-title',
            routerLink: '/buildings-management/buildings/dashboard',
            icon: 'assets/images/left-menu/dashboard.svg',
            manager: true,
          },
          {
            name: 'Bookings Management',
            translation: 'left-menu-bookings',
            featureId: 'booking_management',
            routerLink: '/buildings-management/reservations/management',
            icon: 'assets/images/left-menu/calendar.svg',
            manager: true,
          },
          {
            name: 'Settings',
            translation: 'left-menu-settings',
            routerLink: '/buildings-management/buildings/settings',
            icon: 'assets/images/left-menu/settings.svg',
            manager: true,
          },
        ],
      },
      {
        category: 'Facilities',
        translation: 'left-menu-category-facilities',
        links: [
          {
            name: 'Floors',
            translation: 'editor-buildings-tab-floors',
            routerLink: '/buildings-management/buildings/floors',
            icon: 'assets/images/left-menu/Floors.svg',
            manager: true,
          },
          {
            name: 'Spaces',
            translation: 'realtime-map-rooms',
            routerLink: '/buildings-management/buildings/spaces',
            icon: '/assets/images/my-buildings/spaces.svg',
            manager: true,
          },
          {
            name: 'Equipment',
            translation: 'buildings-tab-equipment',
            routerLink: '/buildings-management/buildings/equipment',
            icon: 'assets/images/my-buildings/projector.svg',
            manager: true,
          },
          {
            name: 'Lockers',
            featureId: 'lockers_gantner_bt',
            translation: 'left-menu-lockers',
            routerLink: '/buildings-management/buildings/lockers',
            icon: 'assets/images/left-menu/locker.svg',
            manager: true,
          },
          {
            name: 'Companies',
            translation: 'buildings-companies-tab-groups',
            // featureId: 'companies',
            routerLink: '/buildings-management/buildings/companies',
            icon: '/assets/images/left-menu/building.svg',
            manager: true,
          },
          {
            name: 'Alerts',
            translation: 'left-menu-alerts',
            // featureId: 'companies',
            routerLink: '/buildings-management/management/environmental-alerts',
            icon: 'assets/images/left-menu/wellbeing.svg',
          },
          {
            name: 'Resource connections',
            translation: 'calendar-resource-connections-title',
            routerLink: '/buildings-management/management/resource-connection',
            icon: 'assets/images/left-menu/Departments.svg',
            manager: true,
          },
        ],
      },
      {
        category: 'People',
        translation: 'left-menu-people',
        links: [
          {
            name: 'Managers',
            translation: 'management-building-managers-title',
            routerLink: '/buildings-management/management/building-managers',
            icon: 'assets/images/left-menu/account-edit.svg',
            manager: true,
          },
          {
            name: 'Booking setings',
            translation: 'check-in-rules',
            routerLink: '/buildings-management/booking-rules',
            icon: 'assets/images/left-menu/calendar.svg',
            manager: true,
          },
          {
            name: 'Space blockouts',
            translation: 'admin-blockout-event',
            routerLink: 'buildings-management/management/blockout-events',
            icon: 'assets/images/left-menu/block-out.svg',
            featureId: 'blockouts',
            manager: true,
          },
          {
            name: 'Space groups',
            translation: 'admin-space-groups-title',
            routerLink: '/buildings-management/management/building-access/space-groups',
            icon: 'assets/images/left-menu/Space_groups.svg',
            manager: true,
          },
          {
            name: 'Departments',
            translation: 'buildings-tab-groups',
            routerLink: '/buildings-management/management/building-access/departments',
            icon: 'assets/images/left-menu/Departments.svg',
            manager: true,
          },
          {
            name: 'Teams',
            translation: 'admin-users-groups',
            routerLink: '/buildings-management/management/building-access/user-groups',
            icon: 'assets/images/left-menu/User_groups.svg',
            manager: true,
          },
          {
            name: 'Activities',
            translation: 'management-activities-title',
            routerLink: '/buildings-management/management/building-access/activities',
            icon: 'assets/images/left-menu/calendar.svg',
            manager: true,
          },
        ],
      },
    ],
  },
  {
    route: '/insights/esg',
    header: 'ESG',
    returnTo: '/',
    selectBuilding: true,
    set: [
      {
        category: 'ESG',
        links: [
          {
            name: 'Homepage',
            translation: 'left-menu-esg-homepage',
            featureId: 'esg',
            routerLink: '/insights/esg/homepage',
            icon: 'assets/images/left-menu/dashboard.svg',
          },
          {
            name: 'Energy',
            translation: 'left-menu-esg-energy',
            featureId: 'esg_energy',
            routerLink: '/insights/esg/energy',
            icon: 'assets/images/left-menu/Energy.svg',
          },
          {
            name: 'Waste & Water',
            translation: 'left-menu-esg-waste-water',
            featureId: 'esg_waste_water',
            routerLink: '/insights/esg/waste-water',
            icon: 'assets/images/left-menu/Humidity.svg',
          },
          {
            name: 'Indoor Air Quality',
            translation: 'left-menu-esg-air-quality',
            featureId: 'esg_air_quality',
            routerLink: '/insights/esg/air-quality',
            icon: 'assets/images/left-menu/Air_condition.svg',
          },
          {
            name: 'Environment',
            translation: 'left-menu-esg-environment',
            featureId: 'esg_environment',
            routerLink: '/insights/esg/environment',
            icon: 'assets/images/left-menu/esg_building.svg',
          },
          {
            name: 'Building usage',
            translation: 'left-menu-esg-building-usage',
            featureId: 'esg_building_usage',
            routerLink: '/insights/esg/building-usage',
            icon: 'assets/images/left-menu/Office_people.svg',
          },
          {
            name: 'Reporting',
            translation: 'left-menu-esg-reporting',
            featureId: 'esg_reporting',
            routerLink: '/insights/esg/reporting',
            icon: 'assets/images/left-menu/feedback.svg',
          },
          {
            name: 'Inputs',
            translation: 'left-menu-esg-inputs',
            featureId: 'esg_inputs',
            routerLink: '/insights/esg/inputs',
            icon: 'assets/images/left-menu/Edit_old.svg',
          },
        ],
      },
    ],
  },
  {
    id: 'analytics',
    route: '/insights',
    header: 'left-menu-analytics',
    returnTo: '/',
    selectBuilding: true,
    set: [
      {
        category: 'Analytics',
        links: [
          {
            id: 'analytics-dashboard',
            name: 'Dashboard',
            translation: 'left-menu-dashboard',
            featureId: 'analytics',
            routerLink: '/insights/dashboard',
            icon: 'assets/images/left-menu/dashboard.svg',
          },
          {
            id: 'analytics-ai',
            name: 'Spaceti AI',
            translation: 'left-menu-spaceti-ai',
            featureId: 'spaceti_ai',
            routerLink: '/insights/spaceti-ai',
            icon: 'assets/images/left-menu/ai-tool.svg',
            manager: true,
          },
          {
            id: 'analytics-heatmap',
            name: 'Heat map',
            translation: 'left-menu-heatmap',
            featureId: 'analytics_heatmaps',
            routerLink: '/insights/heatmaps',
            icon: 'assets/images/left-menu/heatmap-analytics.svg',
          },
          {
            id: 'analytics-bookings',
            name: 'Bookings analytics',
            translation: 'left-menu-bookings-analytics',
            featureId: 'analytics_bookings',
            routerLink: '/insights/bookings',
            icon: 'assets/images/left-menu/bookings-analytics.svg',
          },
          {
            id: 'analytics-occupancy',
            name: 'Occupancy',
            translation: 'left-menu-occupancy',
            featureId: 'analytics',
            icon: 'assets/images/left-menu/occupancy.svg',
            routerLink: '/insights/occupancy',
          },
          {
            id: 'analytics-environment',
            name: 'Environment',
            translation: 'left-menu-environment',
            featureId: 'analytics',
            icon: 'assets/images/left-menu/wellbeing.svg',
            routerLink: '/insights/environment/',
          },
        ],
      },
    ],
  },
  {
    route: '/',
    set: [
      {
        category: 'Home',
        translation: 'Home',
        links: [
          {
            name: 'Homepage',
            translation: 'left-menu-homepage',
            featureId: 'homepage',
            routerLink: '/reservations/homepage',
            icon: 'assets/images/left-menu/dashboard.svg',
          },
          {
            name: 'Realtime map',
            translation: 'left-menu-realtime-map',
            featureId: 'map',
            routerLink: '/realtime-view/map',
            icon: 'assets/images/left-menu/map.svg',
          },
          {
            id: 'analytics',
            name: 'Analytics',
            translation: 'left-menu-analytics',
            featureId: 'analytics',
            routerLink: '/insights/dashboard',
            icon: 'assets/images/left-menu/occupancy.svg',
          },
          {
            name: 'ESG',
            translation: 'left-menu-esg',
            // featureId: 'esg',
            routerLink: '/insights/esg/homepage',
            icon: 'assets/images/left-menu/wellbeing.svg',
            manager: true,
          },
        ],
      },
      {
        category: 'Application',
        translation: 'left-menu-category-application',
        links: [
          {
            name: 'My bookings',
            translation: 'bookings-title',
            routerLink: '/reservations/my-bookings',
            featureId: 'bookings',
            icon: 'assets/images/left-menu/calendar.svg',
            preserveQueryParams: true,
          },
          {
            name: 'My meetings',
            translation: 'meetings-title',
            featureId: 'calendar',
            routerLink: '/reservations/my-meetings',
            icon: 'assets/images/left-menu/meeting-room.svg',
          },
          {
            name: 'Bookings Timeline',
            featureId: 'timeline',
            translation: 'bookings-timeline-title',
            routerLink: '/reservations/timeline',
            icon: 'assets/images/left-menu/Overview_list.svg',
          },
          {
            name: 'Newsfeed',
            translation: 'left-menu-news-feed',
            routerLink: '/feed/news',
            icon: 'assets/images/left-menu/news.svg',
            featureId: 'newsfeed',
          },
          {
            name: 'Events',
            translation: 'events-feed-title',
            routerLink: '/feed/events',
            icon: 'assets/images/left-menu/Star.svg',
          },
        ],
      },
      {
        category: 'Administration',
        translation: 'left-menu-category-administration',
        links: [
          {
            name: 'My Buildings',
            translation: 'left-menu-my-buildings',
            routerLink: '/buildings/building',
            icon: 'assets/images/left-menu/building.svg',
            manager: true,
            excludedFeatureId: 'building_management',
          },
          {
            name: 'Dashboard',
            translation: 'left-menu-buildings-management',
            routerLink: '/buildings-management/buildings/dashboard',
            icon: 'assets/images/left-menu/dashboard.svg',
            featureId: 'building_management',
          },
          {
            name: 'Bookings',
            translation: 'left-menu-bookings',
            icon: 'assets/images/left-menu/calendar.svg',
            preserveQueryParams: true,
            links: [
              {
                name: 'Bookings Overview',
                featureId: 'booking_management',
                translation: 'bookings-admin-title',
                routerLink: '/reservations/overview',
                icon: 'assets/images/left-menu/overview.svg',
              },
              {
                name: 'Requests',
                featureId: 'booking_request_management',
                translation: 'bookings-admin-requests-tab-short-title',
                routerLink: '/reservations/requests',
                icon: 'assets/images/left-menu/booking_requests.svg',
              },
              {
                name: 'Requests v2',
                featureId: 'booking_request_management',
                translation: 'Requests v2',
                routerLink: '/reservations/requests2',
                icon: 'assets/images/left-menu/booking_requests.svg',
              },
            ],
          },
          {
            name: 'Services',
            translation: 'services-title',
            icon: 'assets/images/left-menu/services.svg',
            preserveQueryParams: true,
            manager: true,
            links: [
              {
                name: 'Parking billing',
                featureId: 'smart_parking',
                translation: 'left-menu-parking-billing',
                routerLink: '/parking-billing',
                icon: 'assets/images/left-menu/parking.svg',
                manager: true,
                includedTenants: ['deloitte', 'spacetihq'],
              },
            ],
          },
        ],
      },
      {
        category: 'Facilities',
        translation: 'left-menu-category-facilities',
        links: [
          {
            name: 'Events management',
            translation: 'management-events-title',
            routerLink: '/management/events',
            icon: 'assets/images/left-menu/Star.svg',
            manager: true,
          },
          {
            name: 'Roles',
            translation: 'management-roles-title',
            routerLink: '/buildings/roles',
            icon: 'assets/images/left-menu/dashboard.svg',
            manager: true,
          },
          {
            name: 'Warner Music',
            translation: 'left-menu-warner-music',
            routerLink: '/management/warner-music/anrs',
            icon: 'assets/images/left-menu/user.svg',
            includedTenants: ['spacetihq'],
            manager: true,
          },
          {
            name: 'Asset management',
            translation: 'left-menu-asset-management',
            routerLink: '/management/asset-management',
            icon: 'assets/images/general/space-groups.svg',
            featureId: 'assets',
          },
          {
            name: 'Lockers',
            featureId: 'lockers_gantner_bt',
            translation: 'left-menu-lockers',
            routerLink: '/buildings-management/buildings/lockers',
            icon: 'assets/images/left-menu/locker.svg',
            manager: true,
            queryParams: { msg: true },
          },
          {
            name: 'Reports',
            featureId: 'issues',
            translation: 'left-menu-reports',
            routerLink: '/reports',
            icon: 'assets/images/left-menu/feedback.svg',
            manager: true,
          },

          {
            name: 'Hardware management',
            translation: 'left-menu-hardware-management',
            routerLink: '/hardware-management',
            icon: 'assets/images/hw/Sensor_black.svg',
            manager: true,
            links: [
              {
                name: 'Sensors',
                translation: 'analytics-tabs-data-sensors',
                routerLink: '/hardware-management/office-hardware',
                icon: 'assets/images/hw/Sensor_black.svg',
                manager: true,
              },
              {
                name: 'Gateways',
                translation: 'left-menu-gateways',
                routerLink: '/hardware-management/gateways',
                icon: 'assets/images/hw/Gateways.svg',
                manager: true,
              },
              {
                name: 'Digital Reception',
                featureId: 'digital_reception',
                translation: 'left-menu-digital-reception',
                routerLink: '/hardware-management/digital-reception',
                icon: 'assets/images/left-menu/digital_reception.svg',
                manager: true,
              },
              {
                name: 'Tablets',
                featureId: 'tablets_management',
                translation: 'left-menu-tablets-management',
                routerLink: '/hardware-management/tablets',
                icon: 'assets/images/left-menu/tablets.svg',
                manager: true,
              },
            ],
          },
          {
            name: 'Users',
            featureId: 'user_management',
            translation: 'left-menu-users',
            routerLink: '/users',
            icon: 'assets/images/left-menu/user.svg',
          },
          {
            name: 'Settings',
            translation: 'left-menu-settings',
            icon: 'assets/images/left-menu/account-edit.svg',
            manager: true,
            links: [
              {
                name: 'Meetings',
                featureId: 'calendar',
                translation: 'left-menu-integrations',
                routerLink: '/calendar-integrations',
                icon: 'assets/images/left-menu/meeting-room.svg',
                manager: true,
              },
              {
                name: 'Organization settings',
                translation: 'left-menu-customization',
                routerLink: '/settings',
                icon: 'assets/images/left-menu/settings.svg',
                manager: true,
              },
              {
                name: 'Public API',
                translation: 'public-api-title',
                routerLink: '/public-api',
                icon: 'assets/images/left-menu/account-edit.svg',
                manager: true,
              },
            ],
          },
        ],
      },
    ],
  },
];
