import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrgRegistrationInfo, UserRegistrationInfo } from '@spaceti/models';
import { RegistrationState } from '../models';

export const registrationState =
  createFeatureSelector<RegistrationState>('registration');

export const getRegistrationStep = createSelector<
  RegistrationState,
  [RegistrationState],
  number
>(registrationState, (x) => x.registrationStep);

export const getUserRegistrationInfo = createSelector<
  RegistrationState,
  [RegistrationState],
  UserRegistrationInfo
>(registrationState, (x) => x.userInfo);

export const getOrgRegistrationInfo = createSelector<
  RegistrationState,
  [RegistrationState],
  OrgRegistrationInfo
>(registrationState, (x) => x.orgInfo);

export const getRegistrationStatus = createSelector<
  RegistrationState,
  [RegistrationState],
  { success: boolean; resultCode: number }
>(registrationState, (x) => x.registrationStatus);

export const getRegistrationLoader = createSelector<
  RegistrationState,
  [RegistrationState],
  boolean
>(registrationState, (x) => x.loading);
