import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import { delay, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { getPermission, LocationService } from '@spaceti/core/common';
import { authCiscoIntegration } from '../helpers';
import { getOrganization, getUserEmail } from '../selectors/login.selectors';
import { AuthService, LoginService, PassportService } from '../services';
import { Router } from '@angular/router';

@Injectable()
export class LoginEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<any>,
    private loginService: LoginService,
    private authService: AuthService,
    private locationService: LocationService,
    private passportService: PassportService
  ) {}

  $loadOrganization = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.LoadOrganizationAction),
      switchMap((action) => {
        return forkJoin([
          this.loginService.loadOrganization(action.organization),
          this.authService.checkSession(),
        ]).pipe(
          map(([organization, session]) => {
            if (organization.success) {
              if (session.success) {
                /** If organization exists and user is already logged in redirect to dashboard */
                if (!action.integration) {
                  const accessToken = this.authService.decode(session.data);
                  const role = this.authService.getRole(accessToken, organization.data.subdomain);
                  if (role !== 'pending')
                    this.locationService.redirectAfterLogin(organization.data.subdomain, {
                      returnTo: action.returnTo,
                      accessToken: session.data,
                    });
                  else if (role === 'pending')
                    this.router.navigate(['account/access/pending'], {
                      state: { organization: organization.data.name },
                    });
                } else {
                  if (action.integration.includes('qr')) {
                    const integ = action.integration.split(':');
                    this.locationService.redirectQRAfterLogin(organization.data.subdomain, {
                      accessToken: session.data,
                      space_id: Number(integ[1]),
                    });
                  }
                  /** If integration detected  */
                  this.authService.authorize(authCiscoIntegration(organization.data.subdomain));
                }
              }
              if (action.organization === 'wmg') {
                this.store.dispatch(
                  actions.LoadSupportAction({
                    organization: action.organization,
                  })
                );
                this.store.dispatch(
                  actions.LoadSettingsAction({
                    organization: action.organization,
                  })
                );
              }
              if (organization.data.features.includes('passport')) {
                this.passportService.login(
                  organization.data.subdomain,
                  action.integration,
                  action.returnTo
                );
              }

              return actions.LoadOrganizationSuccessfulAction({
                organization: organization.data,
              });
            } else {
              return actions.LoadOrganizationFailedAction({
                error: organization.error,
              });
            }
          })
        );
      })
    )
  );

  $loadSupport = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.LoadSupportAction),
      switchMap((action) =>
        this.loginService.loadSupport(action.organization).pipe(
          map((data) =>
            actions.LoadSupportSuccessfulAction({
              numbers: data?.data ?? [],
            })
          )
        )
      )
    )
  );

  $loadSettings = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.LoadSettingsAction),
      switchMap((action) =>
        this.loginService.loadSettings(action.organization).pipe(
          map((data) =>
            actions.LoadSettingsSuccessfulAction({
              settings: data?.data ?? [],
            })
          )
        )
      )
    )
  );

  $loginContinue = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.LoginContinueAction),
      withLatestFrom(this.store.select(getOrganization), this.store.select(getUserEmail)),
      switchMap(([action, organization, userEmail]) => {
        if (action.email === userEmail.data && action.password) {
          return of(
            actions.PassValidationAction({
              email: userEmail.data,
              password: action.password,
              organization: organization.data?.subdomain ?? 'auth',
              integration: action.integration,
              returnTo: action.returnTo,
            })
          );
        } else {
          return of(actions.UserValidationAction({ email: action.email }));
        }
      })
    )
  );

  $userValidation = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.UserValidationAction),
      withLatestFrom(this.store.select(getOrganization)),
      switchMap(([action, organization]) =>
        this.loginService.validateUser(organization.data?.subdomain, action.email).pipe(
          map((data) => {
            return data.success && data.data?.exists
              ? actions.UserValidationSuccessfulAction({
                  email: action.email,
                })
              : actions.UserValidationFailedAction({
                  email: action.email,
                  error: 'User not found',
                });
          })
        )
      )
    )
  );

  $passValidation = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.PassValidationAction),
      switchMap((action) => {
        return this.authService.login(
          action.email,
          action.password,
          action.organization,
          action.integration,
          action.returnTo
        );
      }),
      map((data) => {
        return data.success
          ? actions.PassValidationSuccessfulAction()
          : actions.PassValidationFailedAction({ error: data.error });
      })
    )
  );
}
